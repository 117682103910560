import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../../components/HeaderComponent/HeaderComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { SimuladoData } from '../../../interfaces/Simulados';
import { getSimuladosFirestore } from '../../../databases/firestore';
import Simulados from '../../../components/SimuladoGabaritoStudent/SimuladoGabaritoStudent';
import minhaProvaLogo from '../../../assets/images/logo-minhaprova.png'
import './StudentSimulados.scss'
import "../../../components/VideoComponent/VideoComponent.scss";
import { auth } from '../../../databases/firebaseConfig';

const StudentSimulados: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const uid = state?.uid || localStorage.getItem('code');
  const nome = state?.userName;
  const logoUrl = state?.logoUrl;
  const imgUrl = state?.imgUrl;

  const [simulados, setSimulados] = useState<SimuladoData[]>([]);

  // Check if the user is already signed in
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {

    const loadSimulados = async () => {
      try {
        const simuladosData = await getSimuladosFirestore();
        // Ordena os simulados por title
        simuladosData.sort((a, b) => a.title.localeCompare(b.title));
        setSimulados(simuladosData);
      } catch (error: any) {
        console.error("Erro ao carregar simulados:", error.message);
      }
    };
    // Carrega a lista de simulados ao montar o componente
    loadSimulados();

}, []);

  useEffect(() => {
    // Carrega a imagem de fundo ao montar o componente
    const loadBackgroundImage = async () => {
      try {
        // Aplique a URL no CSS
        document.documentElement.style.setProperty(
          "--background-image-student",
          "url(" + imgUrl + ")"
        );

        document.documentElement.style.setProperty(
          "--background-image-mobile-student",
          "url(" + imgUrl + ")"
        );

      } catch (error) {
        console.error("Erro ao carregar a imagem de fundo:", error);
      }
    };

    // Carrega a imagem de fundo ao montar o componente
    loadBackgroundImage();
  }, []);


  const handleSimuladoNavigate = () => {
    const simuladoUrl = 'https://app.minhaprova.com.br/student/login?code=matdozero';
    window.open(simuladoUrl, '_blank');
      
  };

  
  
  return (
    <div className='simulado-page' style={{color: '#000'}}>
      <HeaderComponent urlPhoto={uid} isFirstPage={'homeStudent'} nome={nome} logoUrl={logoUrl} curso={state?.curso} />
      <div className="simulado-page_container">
        {/*<h2 className="title-simulados">SELECIONE O SIMULADO QUE VOCÊ DESEJA CORRIGIR</h2>*/}
        <div style={{width: '89%', margin: '0 auto'}}>
          {/* <Simulados simulados={simulados}/> */}
          <iframe
            title="Minha Prova - Simulados"
            width="100%"
            height="1000px"
            src="https://app.minhaprova.com.br/student/login?code=matdozero"
            allowFullScreen
            sandbox="allow-scripts allow-same-origin allow-forms allow-downloads allow-top-navigation allow-modals allow-popups allow-popups-to-escape-sandbox"
            style={{borderRadius: '10px'}}
          ></iframe>
        </div>


      </div>

      <div className="video-page simulado-video-page">
        <div className="video-page_container-description simulado-video-page_container-description">
          <div className="buttons-footer">
            <div
              className="material-button"
              style={{ cursor: "pointer" }}
              onClick={handleSimuladoNavigate}
            >
              <div className="material-button_icon" style={{backgroundColor: 'white'}}>
                <img src={minhaProvaLogo} alt='minha prova' width={200} />
              </div>
              <div className="material-button_info">
                <strong className="material-button_info-title" style={{color: 'white'}}>
                  Caso enfrente problemas na página acima
                </strong>
                <p className="material-button_info-desc">
                  clique aqui para acessar os resultados dos simulados de aula e os simulados
                  exclusivos sobre as aulas nesse novo link.
                </p>
              </div>
              <div className="separador"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentSimulados;
